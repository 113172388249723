<template>
	<w-layout align-center row wrap>
		<w-flex offset-xs1 xs3>
			<v-text-field v-model="searchedText" clearable :label="$t('actions.search')" prepend-inner-icon="search" />
		</w-flex>
		<w-flex offset-xs4 xs3>
			<SelectWithChips
				v-model="selectedUsers"
				:items="users"
				item-value="id"
				item-text="username"
				:label="$t('actions.filter')"
				:max-selected-items-showed="2"
				multiple
				prepend-inner-icon="filter_list"
			/>
		</w-flex>
	</w-layout>
</template>

<script>
import CompanyAssignmentModuleGuard from '@/mixins/ModulesGuards/TeamManager/CompanyAssignmentModuleGuard'

export default {
	name: 'CompanyAssignmentSectionHeader',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	mixins: [CompanyAssignmentModuleGuard],
	computed: {
		searchedText: {
			get: function () {
				return this.service.getSearchedText()
			},
			set: function (val) {
				this.service.setSearchedText(val)
			}
		},
		selectedUsers: {
			get: function () {
				return this.service.getSearchedUsers()
			},
			set: function (val) {
				this.service.setSearchedUsers(val)
			}
		},
		users: function () {
			return this.service.getUsers()
		}
	}
}
</script>
